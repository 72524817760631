// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { ko, en } from 'vuetify/locale'

// Vuetify
import { createVuetify } from 'vuetify'

let yysLightTheme = {
    dark: false,
    colors: {
      background: '#FFFFFF',
      surface: '#FFFFFF',
      primary: '#6200EE',
      'primary-darken-1': '#3700B3',
      secondary: '#03DAC6',
      'secondary-darken-1': '#018786',
      error: '#B00020',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FB8C00',
      white: '#FFFFFF',
      black: '#000000',
      black001: '#181818',
      black002: '#1e1f21',
      grey001: '#f0f1f3',
      grey002: '#a5adb8',
      grey003: '#969faa',
      grey004: '#39414d',
      grey005: '#54595f',
      grey006: '#343531',
      grey007: '#f7f7f7',
      grey008: '#ccc',
      yellow001: '#fbe4a6',
      purple001: '#9933ff',
      blue001: '#005593',
      blue002: '#1B81DF',
      blue003: '#24ACEE',
      blue004: '#053866',
      blue005: '#6610f2',
      blue006: '#004dc1',
      blue007: '#074499',
      blue008: '#f4f7fb',
      blue009: '#13192d',
      blue010: '#716AE5',
      blue011: '#0575e6',
    },
  }

export default createVuetify({
    locale: {
        locale: 'ko',
        fallback: 'en',
        messages: { ko, en },
    },
    theme: {
        defaultTheme: 'yysLightTheme',
        themes: {
            yysLightTheme,
        },
        dark: false,
    },
})
