import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { loadFonts } from './plugins/webfontloader'
import { createMetaManager, defaultConfig } from 'vue-meta'

import axios from 'axios'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import moment from 'moment'
import 'moment/locale/ko'

// 뷰 및 전역 객체 설정
const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.config.globalProperties.$moment = moment

const meta = createMetaManager(
    false, {
        ...defaultConfig,
        meta: { tag: 'meta', nameless: true },
    }
)
loadFonts()

app.use(router)
  .use(vuetify)
  .use(pinia)
  .use(meta)
  .provide('$axios', axios)
  .mount('#app')