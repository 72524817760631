<template>
    <v-app>
        <metainfo />
        <nav class="d-inline-block" style="width: 0px; height: 0px; line-height: 0;">
            <ul>
                <li>
                    <a class="text-white" href="https://www.yoyangsys.co.kr/contact-sales">무료체험하기</a>
                </li>
                <li>
                    <a class="text-white" href="https://www.yoyangsys.co.kr/pricing">이용요금안내</a>
                </li>
                <li>
                    <a class="text-white" href="https://www.yoyangsys.co.kr/svc-bnftrcrd">시설급여</a>
                </li>
            </ul>
        </nav>
        <router-view></router-view>
    </v-app>
</template>

<script>
import { onMounted } from 'vue';
import { useMeta } from 'vue-meta'

require('./styles/css/cmm.css')

export default {
    name: 'App',

    components: {
       
    },

    props: {
     
    },

    // composition api
    setup() {

        onMounted(() => {
            useMeta({
                title: '요양시스 - 노인장기요양 관리프로그램',
                meta: [
                    { name: 'naver-site-verification', content: '9bc9e84f8e01b087ea300b644a7243e0b693c3eb' },
                    { name: 'google-site-verification', content: '' },
                    { name: 'description', content: '장기요양시설 운영을 위한 최적의 프로그램, 요양시스', vmid: 'description'},
                    { name: 'keywords', content: '장기요양,관리프로그램' },
                    { name: 'robots', content: 'index,follow' },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:title', content: '요양시스 - 노인장기요양 관리프로그램' },
                    { property: 'og:description', content: '장기요양시설 운영을 위한 최적의 프로그램, 요양시스' },
                    { property: 'og:url', content: 'https://www.yoyangsys.co.kr' },
                ]
            })
        })
    },
}
</script>